import { render, staticRenderFns } from "./orderSuccessPage.vue?vue&type=template&id=647cc6cd&scoped=true&"
import script from "./orderSuccessPage.vue?vue&type=script&lang=js&"
export * from "./orderSuccessPage.vue?vue&type=script&lang=js&"
import style0 from "./orderSuccessPage.vue?vue&type=style&index=0&id=647cc6cd&prod&lang=scss&scoped=true&"
import style1 from "./orderSuccessPage.vue?vue&type=style&index=1&id=647cc6cd&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647cc6cd",
  null
  
)

export default component.exports